














































import $ from 'jquery';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ToolboxKangorouBaby extends Vue {
}
