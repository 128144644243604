




























































import $ from 'jquery';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ToolboxSecurity extends Vue {
}
