













import { Component, Vue } from "vue-property-decorator";

@Component
export default class Booking extends Vue {

  mounted() {
    let bookingScript = document.createElement('script');
    bookingScript.setAttribute('class', 'mra_script_iframe')
    bookingScript.setAttribute('src', 'https://booking.myrezapp.com/bundles/myrezappdashboard/js/Embed/myrezapp_3rdparty_iframe.js')
    bookingScript.setAttribute('data-key', 'c4e27a0c26aedf2a2ac9d1737649518d')
    bookingScript.setAttribute('data-id', 'mra_iframe_content_list13761')
    bookingScript.setAttribute('data-lang', 'fr')
    bookingScript.setAttribute('data-mode', 'listing')
    bookingScript.setAttribute('data-height', '1000px')
    /*bookingScript.setAttribute('src', '//widget.simplybook.it/v2/widget/widget.js');
    bookingScript.onload = function() {
        new SimplybookWidget({"widget_type":"iframe","url":"https:\/\/littlecoconlove.simplybook.it","theme":"skittish","theme_settings":{"timeline_hide_unavailable":"1","hide_past_days":"0","timeline_show_end_time":"0","timeline_modern_display":"as_slots","sb_base_color":"#d0b8ac","display_item_mode":"block","body_bg_color":"#fcf7f2","sb_review_image":"6","sb_review_image_preview":"\/uploads\/littlecoconlove\/image_files\/preview\/464f33cee8c5e4a855fab61737fc18e9.png","dark_font_color":"#403234","light_font_color":"#ffffff","btn_color_1":"#d0b8ac","sb_company_label_color":"#403234","hide_img_mode":"0","sb_busy":"#c7b3b3","sb_available":"#efe5dc"},"timeline":"modern","datepicker":"top_calendar","is_rtl":false,"app_config":{"clear_session":0,"allow_switch_to_ada":0,"predefined":[]},"container_id":"sbw_wtlu4r"});
    };
    bookingScript.async = true;*/
    document?.getElementById("booking")?.appendChild(bookingScript);

    console.log(this?.$refs?.booking);

  }
}
