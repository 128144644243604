







































































import { Component, Vue } from "vue-property-decorator";

import Citation from "@/components/Citation.vue";
import WorkshopItem from "@/components/WorkshopItem.vue";

@Component({
  components: { Citation, WorkshopItem },
})
export default class Home extends Vue {

  scrollToBottom() {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    (document.getElementById("contact-form-input-phone-or-mail") as HTMLInputElement).select();
  }
}
