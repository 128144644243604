











import { Component, Vue } from "vue-property-decorator";

import { CONTACT } from "@/config";
import ContactItem from "@/components/ContactItem.vue";

@Component({
  components: { ContactItem }
})
export default class SubHeader extends Vue {
  private CONTACT = CONTACT;
}
