
























import $ from 'jquery';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ToolboxPasswordKangorouBaby extends Vue {
  private password: string = '';
  private badPassword: boolean = false;

  accessToolboxKangorouBaby() {
    if (this.password === "littlecoconvip") {
      this.$router.push('/boite-outils/portage-physiologique');
    } else {
      this.badPassword = true;
    }
  }
}
