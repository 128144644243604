import Vue from "vue";
import VueCompositionApi from '@vue/composition-api'
import VueMeta from 'vue-meta'
import VueGtag from 'vue-gtag'
import App from "./App.vue";
import router from "./router";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faLock, faBars, faCheck, faClock, faEuroSign, faInfoCircle, faMapMarkerAlt, faPhone, faSpinner, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueAnalytics from 'vue-analytics'
import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueImg from 'v-img';

import $ from 'jquery';
import 'foundation-sites'

library.add(faLock, faBars, faCheck, faClock, faEnvelope, faEuroSign, faFacebook, faInfoCircle, faInstagram, faMapMarkerAlt, faPhone, faSpinner, faUser, faUsers)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueFbCustomerChat, {
  page_id: '102914455290689',
  theme_color: '#D0B8AC',
  locale: 'fr_FR',
})

/*Vue.use(VueAnalytics, {
  id: '327960363',
  router
})*/

Vue.use(VueGtag, {
    config: {
        id: 'G-5T31F3DE2M',
        params: {
            send_page_view: true
        }
    }
}, router)

//Vue.use(FoundationJs)
Vue.use(VueMeta)
Vue.use(VueImg)
Vue.use(VueCompositionApi)

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
  mounted: () => {
    $(document).foundation();
  },
}).$mount("#app");
