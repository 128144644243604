













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Citation extends Vue {
  @Prop() private text!: string;
  @Prop() private author?: string;
  @Prop() private imgFileName?: string;
}
