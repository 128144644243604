



















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ContactItem extends Vue {
  @Prop() private icon!: string;
  @Prop({default: true}) private isIconAsLink?: boolean;
  @Prop() private text?: string;
  @Prop() private img?: string;
  @Prop({default: true}) private isTextAsLink?: boolean;
  @Prop() private link!: string;
  @Prop() private size!: string;

  goToLink() {
    window.location.href = this.link;
  }
}
