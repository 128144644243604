
































import $ from 'jquery';

import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import SubHeader from "@/components/SubHeader.vue";

export default {
  name: 'app',
  components: {
    Footer, Header, SubHeader
  }
};
